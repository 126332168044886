.game-shop.my-container {
  position: absolute;
  left: 8vw;
  right: 8vw;
  bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.game-shop-actions {
  display: grid;
  grid-template: 1fr 1fr / 3vw 1fr;
  padding: 5px;
  gap: 0.5em;
  align-items: center;
}

.game-rarity-percentage.my-box {
  grid-column: 1 / 3;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  padding: 0;
  border-radius: 8px;
  color: black;
}

.game-rarity-percentage > div {
  display: grid;
  place-content: center;
  font-size: 0.8em;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.game-rarity-percentage > div:first-of-type {
  border-radius: 8px 0 0 8px;
}

.game-rarity-percentage > div:last-of-type {
  border-radius: 0 8px 8px 0;
}

.game-shop-actions .refresh-button {
  font-size: 1em;
  white-space: nowrap;
  padding: 0.25em 0.5em;
  min-height: 2.5em;
  position: relative;
}

.game-shop-actions .refresh-button > img:first-child {
  vertical-align: middle;
  width: 1.25em;
  height: 1.25em;
}

.game-shop-actions .lock-icon {
  padding: 0;
  display: grid;
  place-content: center;
  height: 2.5em;
  font-size: 1em;
}

.game-shop-actions .lock-icon > img:only-child {
  width: 1.5em;
  height: 1.5em;
  margin: 0.25em;
}

.game-additional-pokemons,
.game-regional-pokemons {
  padding: 0 !important;
  color: white;
  background-color: rgb(97, 115, 138);
  overflow: hidden;
}

.game-additional-pokemons h2,
.game-regional-pokemons h2 {
  margin: 0.25em auto 0 auto;
  text-align: center;
}

.game-additional-pokemons p.help,
.game-regional-pokemons p.help {
  margin: 0 auto 0.5em auto;
}

.game-additional-pokemons .grid {
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(6, 1fr);
  grid-auto-flow: column;
  gap: 2px;
  width: 682px;
  height: 360px;
}

.game-regional-pokemons .grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2px;
  width: 682px;
}

.game-additional-pokemons .game-pokemon-portrait,
.game-regional-pokemons .game-pokemon-portrait {
  width: 112px;
  height: 90px;
  min-height: 0;
  min-width: 0;
  border: 2px solid #333;
}

.game-additional-pokemons .game-pokemon-portrait .synergy-icon,
.game-regional-pokemons .game-pokemon-portrait .synergy-icon {
  width: 24px;
  height: 24px;
}

.game-additional-pokemons .grid > p,
.game-regional-pokemons .grid > p {
  grid-row: 1 / 5;
  grid-column: 1 / 7;
  display: grid;
  place-content: center;
  padding: 1em;
}

.game-additional-pools {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 5px;
}

.game-pokemons-store {
  display: flex;
  flex: 1;
  list-style: none;
  gap: 5px;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.game-experience {
  width: 12vw;
  display: grid;
  grid-template: 1fr 1fr / 3vw 1fr;
  padding: 5px;
  gap: 0.5em;
  align-items: center;
  text-align: center;
}

.game-experience > span {
  color: white;
}

.buy-xp-button.bubbly {
  font-size: 1em;
  white-space: nowrap;
  padding: 0.25em 0.5em;
}

.game-experience .progress-bar {
  grid-column: 1 / 3;
  position: relative;
  background: transparent;
  height: 2em;
}

.game-experience .progress-bar > span {
  position: absolute;
  margin: 0px;
  color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  place-content: center;
}

.game-experience .progress-bar > .progress {
  overflow: hidden;
  height: 100%;
}

#game-shop-info {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  display: flex;
  gap: 1em;
  padding: 0 1em;
  pointer-events: none;
}

#game-money-info {
  background-image: url("assets/ui/money-bg.png");
  background-size: 70px 40px;
}

#game-life-info {
  background-image: url("assets/ui/heart-bg.png");
  background-size: 48px 38px;
}

#game-team-info {
  background-image: url("assets/ui/pokeball-bg.png");
  background-size: 70px 40px;
}

#toast-life .toast-player-damage {
  color: red;
  font-weight: bold;
}

#toast-money .toast-player-income {
  color: white;
  font-weight: bold;
}

#toast-life img,
#toast-money img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  display: inline-block;
}

.game-shop .information {
  padding: 0.001px 0.001px 0.001px 36px;
  display: flex;
  justify-content: end;
  color: white;
  border-radius: 12px 12px 0 0;
  pointer-events: initial;
}

.game-shop .information > div {
  background: var(--color-bg-primary);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 0 7px 0 0;
  height: 100%;
  padding: 0 0.5em;
}

.game-shop .information .icon {
  width: 1em;
  height: 1em;
}

@media (aspect-ratio < 4 / 3) {
  .game-shop.my-container {
    flex-wrap: wrap;
  }

  .game-shop-actions {
    min-width: 240px;
    width: auto;
    grid-template: 1fr / 200px min-content min-content;
    order: 2;
  }

  .game-rarity-percentage.my-box {
    grid-column: 1 / 2;
  }

  .game-pokemons-store {
    flex: 0 0 100%;
  }

  .game-additional-pools {
    flex-direction: row;
    order: 3;
  }

  .game-experience {
    min-width: 240px;
    width: auto;
    grid-template: 1fr / auto 120px min-content;
    order: 4;
  }

  .game-experience .progress-bar {
    grid-column: 2 / 3;
    grid-row: 1 / 1;
  }

  .game-experience .buy-xp-button {
    grid-column: 3 / 4;
    grid-row: 1 / 1;
  }

  .game-shop .game-pokemons-store > .game-pokemon-portrait {
    width: 12.5vw;
    --container-height: 10vw;
  }

  .game-shop .game-pokemons-store > .game-pokemon-portrait .synergy-icon {
    width: 2.5vw;
    height: 2.5vw;
  }
}

@media (aspect-ratio > 4 / 3) and (width <= 1280px) {
  #game-wrapper .game-shop {
    transform: scale(0.8);
    transform-origin: bottom left;
    right: 0;
  }
}

@media (aspect-ratio > 4 / 3) {
  #game-wrapper .game-shop {
    max-height: 20vh;
  }
}
