.damage-physical {
  color: #ff6e55;
}

.damage-special {
  color: #7fc9ff;
}

.damage-true {
  color: #ffd800;
}

.description-icon {
  white-space: nowrap;
}

.description-icon > img,
img.description-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-right: 3px;
  vertical-align: bottom;
}

h2 .description-icon > img {
  vertical-align: -0.15em;
  width: 1em;
  height: 1em;
}

.description-icon.icon-star {
  width: 1.25em;
  height: 1.25em;
  vertical-align: -0.25em;
}

.ability-description {
  white-space: pre-line;
}

.ability-value {
  color: rgb(171, 171, 171);
  font-weight: 500;
}

.ability-value .active {
  color: rgb(255, 255, 255);
}

.scales-ap .ability-value {
  color: #440093;
}

.scales-ap .ability-value .active {
  color: #d172ff;
}

.scales-luck .ability-value {
  color: #07c552;
}

.scales-luck .ability-value .active {
  color: #2aed72;
}

.stat-label {
  color: rgb(171, 171, 171);
  white-space: normal;
  font-weight: 500;
}

.status-label {
  color: rgb(210, 190, 150);
  font-weight: 500;
}

.item-label {
  color: rgb(190, 190, 190);
  font-weight: 500;
}
