 #modal-root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-content: center center;
  z-index: 100;
  pointer-events: none;
 }

 dialog::backdrop {
  background-color: #00000080;
 }

.modal {
  position: relative;
  max-width: 95vw;  
  width: fit-content;
  background: transparent;
  border: none;
  pointer-events: all;
}

.modal.my-container {
  padding: 0;
}

.modal[open] {
  animation: modal-slide-in 0.3s forwards;
}

@keyframes modal-slide-in {
  0% {
    top: -10vh;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.modal.anchor-top {
  margin: 2.5vh auto;
}

.modal > header {
  border-bottom: var(--border-thin);
  background-color: var(--color-bg-secondary);
  color: white;
  padding: 0.5em;
  text-align: center;
}

.modal > header .close-btn {
  float: right;
  padding: 0.5em;    
  margin: -0.5em 0;
  cursor: var(--cursor-hover);
  appearance: none;
  background: transparent;
  border: none;
  color: white;
  font-size: 1em;
}

.modal-body {
  padding: 0.5em;
  color: white;
  max-height: 90vh;
  overflow-y: auto;
}

.modal > footer {
  display: flex;
  justify-content: end;
  flex-direction: row-reverse;
  border-top: var(--border-thin);
  background-color: var(--color-bg-secondary);
  color: white;
  padding: 0.5em 1em;
  gap: 1em;
}

.modal.custom-bg {
  background-attachment: local;
  background-position: center;
}

@media (width <= 640px) {
  .modal {
    width: 95vw;
  }
}
