.checkbox-container {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: var(--cursor-hover);
  margin: 0;
}

.checkbox-container span {
  line-height: 1;
}