.keybind-container.my-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.keybind-container dl {
  display: grid;
  grid-template-columns: max-content auto;
  gap: 0.5em;
}

.keybind-container dt {
  grid-column-start: 1;
  text-align: right;
}

.keybind-container dd {
  grid-column-start: 2;
}

kbd.remappable {
  cursor: var(--cursor-hover);
}

kbd.remapping {
  background-color: var(--color-bg-accent);
}

kbd.conflict {
  background-color: red;
}
