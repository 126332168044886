.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
}

.progress,
.progress-stacked {
  display: flex;
  height: 16px;
  overflow: hidden; 
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width .6s ease;
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.my-progress {
  border-image-slice: none;
  border-image-width: none;
  border-image-repeat: none;
  border-image-source: none;
  border-image-outset: none;
  border-radius: 12px;
  border: var(--border-thin);
  width: 100%;
  height: 48px;
  margin: 4px;
  background-color: var(--color-bg-secondary);
  color: white;
  appearance: none;
  -webkit-appearance: none;
}

.my-progress::-webkit-progress-bar {
  background-color: var(--color-bg-secondary);
  border-radius: 12px;
}

.my-progress::-webkit-progress-value {
  background-color: var(--color-bg-accent);
  border-radius: 8px;
}

.my-progress::-moz-progress-bar {
  background-color: var(--color-bg-accent);
  border-radius: 12px;
}

.my-progress::-ms-fill {
  background-color: var(--color-bg-secondary);
  border: none;
  border-radius: 12px;
}
