.wiki-pokemon-detail {
  display: grid;
  grid-template-columns: calc(320px + 1em) 16em 14em 1fr;
  gap: 1em;
  padding: 0.5em 0;
}

.wiki-pokemon-detail .game-pokemon-detail-tooltip {
  background-color: var(--color-bg-primary);
  color: #fff;
  padding: 5px;
  align-self: start;
  margin: 0;
  max-width: 330px;
}

.wiki-pokemon-detail dt {
  display: inline-block;
  width: 12ch;
  text-align: right;
  color: #ccc;
}

.wiki-pokemon-detail dd {
  display: inline;
  white-space: pre-line;
}

.wiki-pokemon-detail dt::after {
  content: ":";
  margin-right: 1ch;
}

.wiki-pokemon-detail dd::after {
  content: "";
  display: block;
}

.wiki-pokemon-detail dl:nth-child(3) dt {
  width: 16ch;
  text-align: left;
}

.wiki-pokemon-detail dd ul {
  margin: 0;
}

@media (width <= 640px) {
  .wiki-pokemon-detail {
    display: block;
  }
}
