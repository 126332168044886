.profile-modal {
  color: white;
  display: grid;
  grid-template-areas:
    "card     history"
    "search   history"
    "actions  history";
  grid-template-columns: 600px 1fr;
  grid-template-rows: auto auto 1fr;
  height: calc(95vh - 6em);
  width: 1800px;
  max-width: 95vw;
  padding-top: 0;
}

.profile-modal h2 {
  font-size: 2rem;
  margin: 0 0 0.25em 0;
  text-align: center;
}

.profile-modal .profile-box {
  grid-area: card;
}

.profile-modal .search-bar {
  grid-area: search;
  margin: 0.5em 0;
  position: relative;
}

.profile-modal .search-bar input[type="text"] {
  border-radius: 12px;
  padding: 0.375em 0.75em 0.2em 0.75em;
}

.profile-modal .search-bar .clear-button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  width: 42px;
  color: gray;
  z-index: 3;
}

.profile-modal .search-bar .clear-button:hover,
.profile-modal .search-bar .clear-button:focus {
  box-shadow: none;
  color: black;
}

.profile-modal .profile-actions {
  grid-area: actions;
  max-height: 100%;
  overflow-y: scroll;
}

.profile-actions .react-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.profile-actions .react-tabs__tab-panel {
  flex: 1;
  overflow-y: auto;
  background-color: rgb(84, 89, 107);
  border-radius: 0 0 12px 12px;
  border: var(--border-thin);
  border-top: 0;
}

.profile-actions > .my-container {
  height: 100%;
  background-color: var(--color-bg-secondary);
}

.profile-actions > button,
.profile-actions > .my-input-group {
  margin: 0.25em;
}

.profile-modal .game-history-list {
  grid-area: history;
  margin-left: 1em;
  max-height: 100%;
}

.profile-modal .gadgets {
  padding: 0;
}

.profile-modal .gadget {
  list-style: none;
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 0 1em;
  margin-top: 1em;
  border-width: 2px;
}

.profile-modal .gadget > img {
  grid-row: 1/4;
  width: 80px;
  margin: auto;
}

.profile-modal .gadget span {
  font-size: 2rem;
}

.profile-modal .gadget small {
  color: red;
}

.profile-modal .gadget p {
  margin: 0;
  line-height: 1em;
  font-size: 0.75em;
  color: #ccc;
}

@media (width <= 1280px) {
  .profile-modal {
    display: block;
    overflow-y: auto;
    padding: 0 0.5em;
  }

  .profile-modal .game-history-list {
    margin-left: 0;
  }

  .game-history .date {
    justify-self: end;
  }
}
