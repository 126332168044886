#meta-report {
  height: calc(95vh - 6rem);
  color: white;
  width: 1920px;
  max-width: 95vw;
}

#meta-report h2 {
  margin: 0;
}

#meta-report .react-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#meta-report .react-tabs__tab-panel {
  flex: 1;
  overflow-y: auto;
}

#meta-report header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.25em;
}

#meta-report .rank {
  min-width: 2ch;
  text-align: center;
  font-size: 150%;
}

#meta-report label {
  color: #ccc;
  margin: 0;
}

#meta-report .team-comp,
#meta-report .item-stat,
#meta-report .pokemon-family-stat {
  background-color: rgb(84, 89, 107);
  margin-bottom: 0.5em;
  color: white;
  display: grid;
  grid-template-columns: 1ch 132px 5ch 6ch 8ch 5ch 1ch 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  overflow-x: auto;
}

#meta-report .team-comp {
  grid-template-columns: 1ch 12ch 5ch 6ch 8ch 5ch 1ch 1fr;
}

#meta-report .team-comp label {
  display: block;
}

#meta-report .item-stat {
  grid-template-columns: 2ch 48px 16ch 16ch 10ch 1fr;
}

#meta-report .pokemon-family-stat {
  grid-template-columns: 3ch 16ch 10ch 10ch 14ch 1fr;
}

#meta-report .pokemon-family-stat ul {
  padding: 0;
  margin: 0;
}

#meta-report .pokemon-family-stat li {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
