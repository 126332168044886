.game-pokemons-proposition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.game-pokemons-proposition .active {
  cursor: var(--cursor-hover);
  pointer-events: initial;
}

.game-pokemons-proposition > .my-container > h2,
.game-pokemons-proposition > .my-container > p {
  color: white;
  text-align: center;
}

.game-pokemons-proposition-list {
  display: flex;
  padding: 10px;
  gap: 1vw;
  justify-content: center;
}

.game-pokemons-proposition .show-hide-action {
  display: flex;
  justify-content: center;
  margin: 1em;
}

.game-pokemons-proposition-list > .my-box {
  padding: 5px;
}

.game-pokemons-proposition-list > .my-box:hover {
  cursor: var(--cursor-hover);
  background-color: var(--color-bg-accent);
}

.game-pokemons-proposition-list .game-pokemon-portrait {
  margin: 0 auto;
}

.game-pokemons-proposition .additional-pick-item {
  color: white;
  text-align: center;
}

.game-pokemons-proposition .additional-pick-item p {
  font-size: 80%;
  max-width: 240px;
}
