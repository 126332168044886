.emote-menu ul {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4px;
}

.emote-menu li {
  display: block;
  list-style: none;
  position: relative;
}

.emote-menu li img {
  width: 80px;
  height: 80px;
  image-rendering: pixelated;
  border: 1px solid #000000;
  border-radius: 4px;
  box-shadow: 2px 2px #00000060;
}

.emote-menu li .counter {
  position: absolute;
  bottom: 0;
  left: 0.25em;
  opacity: 0.5;
  color: black;
  font-size: 1em;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.emote-menu li img.locked {
  filter: grayscale(1) contrast(0.5);
}

.emote-menu li img:not(.locked) {
  cursor: var(--cursor-hover);
}
