.poster {
  position: relative;
  transition: all 1s ease-out;
  transform-style: preserve-3d;
  cursor: var(--cursor-hover);
  width: 350px;
  height: 500px;
}

.poster img {
  width: 100%;
  height: 100%;
}

.poster.flipped {
  animation: 500ms ease-in-out flipCard;
  animation-fill-mode: forwards;
}

.poster.flipped .front {
  box-shadow: 0 0 4vw var(--rarity-color, white);
}

@keyframes flipCard {
  0% {
    transform: translateX(-50%) rotateY(0) scale(1);
  }

  50% {
    transform: translateX(-50%) rotateY(90deg) rotateZ(-10deg) scale(1.25);
  }

  100% {
    transform: translateX(-50%) rotateY(180deg) scale(1);
  }
}

.poster .back,
.poster .front {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: all 100ms ease-out;
  transform-origin: 25% top;
}

.poster .back {
  transform: rotateY(180deg);
  background: var(--color-bg-secondary);
  border-radius: 12px;
  border: var(--border-thick);
  text-align: left;
  padding: 0.5em;
  font-size: 16px;
  overflow: auto;
  height: 100%;
}

.poster .front {
  transform: rotateY(0);
}

.poster .back img {
  width: 40px;
  height: 40px;
  margin: 2px 0;
}

.poster .back h2 {
  margin-top: 0.5em;
}
