.preparation-menu {
  background-image: url("assets/ui/back1.png");
  background-size: cover;
  background-position-x: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1280px;
}

.preparation-menu h1 {
  text-align: center;
  color: white;
  text-shadow: 2px 2px 2px black;
}

.preparation-menu p,
.preparation-menu label {
  color: white;
  text-shadow: 2px 2px 2px black;
}

.preparation-menu header p {
  text-align: center;
  margin-bottom: 0.5em;
}

.preparation-menu header img {
  width: 2em;
  height: 2em;
  margin-right: 0.5em;

  &.noelo,
  &.scribble,
  &.quickplay {
    border-radius: 50%;
  }
}

.preparation-menu-users {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  align-content: start;
  gap: 1em;
  flex: 1;
}

.preparation-menu-users .pokemon-portrait {
  margin: 2px;
}

.preparation-menu .actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  margin-top: 0.25em;
}

.preparation-menu .actions span {
  color: white;
  text-shadow: 1px 1px 2px black;
}

@media (width <= 640px) {
  .preparation-menu-users {
    grid-template-columns: minmax(0, 1fr);
  }
}
