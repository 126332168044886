/*========================
       ANIMS
  ======================== */
  @keyframes shimmer {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 100% 100%;
    }
  
    100% {
      background-position: 100% 100%;
    }
  }
  
  .shimmer::after {
    content: "";
    pointer-events: none;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: shimmer 2s infinite;
    background: linear-gradient(
      135deg,
      transparent 0%,
      #ffffff20 25%,
      #ffffe080 50%,
      #ffffff20 75%,
      transparent 100%
    );
    background-size: 400% 400%;
    background-repeat: no-repeat;
    cursor: var(--cursor-hover);
  }