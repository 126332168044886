@font-face {
  font-family: Jost;
  src: url("assets/ui/Jost-VF.ttf") format("truetype");
}

html {
  font-family: Jost, sans-serif;
  font-weight: 600;
  font-size: 16px;
}

html[lang="vi"] {
  font-family: system-ui;
}

p,
label,
dt {
  font-weight: normal;
}

button {
  font-weight: 600;
}
