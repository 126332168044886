.preparation-page {
  display: flex;
  height: 100%;
  overflow-y: auto;
}

.preparation-page main {
  display: flex;
  justify-content: center;
  gap: 1em;
  position: relative;
  left: 60px;
  width: calc(100vw - var(--sidebar-width));
  align-items: center;
}

.preparation-page .preparation-menu,
.preparation-page .chat-container {
  height: calc(100vh - 8vw);
}

.preparation-page .preparation-menu {
  flex: 0 1 50em;
}

.preparation-page .chat-container {
  flex: 0 1 30em;
  display: flex;
  flex-direction: column;
}

.preparation-page > footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
}

@media (max-width: 640px) {
  .preparation-page main {
    display: block;
  }

  .preparation-page .preparation-menu,
  .preparation-page .chat-container {
    min-height: initial;
    overflow: auto;
    margin-bottom: 1em;
  }

  .preparation-page .chat-container {
    padding-bottom: 60px;
  }
}
