.game-player {
  cursor: var(--cursor-hover);
  height: 6.5vh;
  width: 6.5vh;
  position: absolute;
  right: 0.5%;
  background-size: cover;
  border-radius: 50%;
  image-rendering: pixelated;
  box-shadow: inset 0 0 0 0.8vh black;
  z-index: 30;
}

.game-player .CircularProgressbar {
  transform: scale(1);
  transition: all 100ms ease-out;
  border-radius: 50%;
  border: 1px solid black;
}

.game-player .CircularProgressbar-path {
  stroke: #f7d51d;
}

.game-player .CircularProgressbar-trail {
  stroke: var(--color-shadow);
}

.game-player.spectated {
  box-shadow: inset 0 0 0 0.4vh black;
}

.game-player.spectated .CircularProgressbar {
  transform: scale(1.25);
  border-width: 4px;
}

.game-player.self .CircularProgressbar-trail {
  stroke-width: 12px;
}

.game-player.self .CircularProgressbar-path {
  stroke: #01ff01;
  stroke-width: 12px;
}

.game-player.dead {
  filter: grayscale(1) brightness(0.5);
}

.game-player .life-text {
  margin: -0.2em auto;
  z-index: -1;
  text-align: center;
  color: white;
  padding: 0;
  line-height: 1em;
  transition: margin 100ms ease-out;
  width: 2.5em;
}

.game-player.spectated .life-text {
  margin-top: calc(-0.2em + 0.65vh);
}
