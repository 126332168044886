aside.sidebar {
  position: absolute;
  height: 100%;
  z-index: 100;
  background-color: var(--color-bg-primary);
}

.sidebar-logo {
  display: flex;
  align-items: center;
  color: white;
  width: 100%;
  background-color: var(--color-bg-primary);
}

.sidebar-logo img {
  width: 54px;
  margin: 8px 0px 8px 4px;
}

.sidebar .sidebar-logo > div {
  overflow: hidden;
}

.sidebar .sidebar-logo h1 {
  color: inherit;
  font-size: 1.2rem;
  white-space: nowrap;
  margin-bottom: 0;
}

.sidebar .sidebar-logo small {
  display: block;
  font-size: 1rem;
  opacity: 0.75;
}

#root .sidebar.ps-collapsed {
  width: 60px;
  min-width: 60px;
}

.sidebar.ps-collapsed .sidebar-logo div {
  color: transparent;
}

.sidebar.ps-collapsed .ps-sidebar-container {
  overflow: hidden !important;
}

.menu-item {
  width: 100%;
}

.menu-item a {
  width: 100%;
  cursor: var(--cursor-hover) !important;
}

.menu-item a span {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ps-menu-root {
  height: 100%;
  background-color: var(--color-bg-primary);
}

.ps-menu-root ul {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.ps-menu-root .spacer {
  flex: 1;
}

.ps-menu-label {
  color: white;
}

#root .ps-menu-button {
  padding-left: 10px;
}

.ps-menu-button .icon {
  position: relative;
  display: flex;
}

.ps-menu-button .notification {
  position: absolute;
  top: 0;
  right: -5px;
  height: 6px;
  font-size: 6px;
}

.menu-item {
  color: #333;
}

.menu-item a.ps-menu-button {
  background: var(--color-bg-secondary);
  border-bottom: 3px solid #00000060;
}

.menu-item:first-of-type a.ps-menu-button,
.spacer + .menu-item a.ps-menu-button,
.additional-links + .menu-item a.ps-menu-button {
  border-top: 3px solid #00000060;
}

.menu-item a.ps-menu-button:hover {
  background: var(--color-bg-accent);
}

.menu-item.red a.ps-menu-button:hover {
  background: #e76e55;
}

.menu-item.discord a.ps-menu-button:hover {
  background: #444ebe;
}

.menu-item.community-servers a.ps-menu-button:hover {
  background: #ee2097;
}

.menu-item.tipeee a.ps-menu-button:hover {
  background: #b13c49;
}

.ps-sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 12px 0 0;
}

#root .ps-sidebar-root {
  border-right: var(--border-thick);
  border-radius: 0 12px 12px 0;
  overflow: auto;
}

@media (max-width: 960px) {
  .ps-sidebar-root,
  #root #game-wrapper .ps-sidebar-root {
    left: 0;
    bottom: 0;
    top: auto;
  }

  #root #game-wrapper,
  #root main {
    left: 0;
    width: 100vw;
  }

  #root #game {
    width: 100vw;
  }

  .ps-sidebar-root.ps-collapsed {
    height: 60px;
    overflow: hidden;
  }

  .sidebar-logo {
    position: sticky;
    order: 2;
    bottom: 0;
    z-index: 2;
  }
}

.additional-links {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin: 0.5em;
  font-size: 0.5em;
  align-items: center;
  text-align: center;
}

.lobby .additional-links a {
  color: #eee;
  animation: appear-after-opening 500ms;
}

.lobby .additional-links a:hover {
  color: #fff;
  text-decoration: underline;
}

.additional-links button {
  width: 100%;
}

@keyframes appear-after-opening {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
