.lobby {
  height: 100%;
  gap: 1vw;
  display: flex;
}

.lobby-container {
  padding: 10px;
  padding-left: calc(var(--sidebar-width) + 10px);
  width: 100%;
}

.lobby section {
  height: 100%;
  width: 25%;
}

.lobby .user-menu {
  grid-area: user;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.lobby .user-menu .react-tabs,
.lobby .user-menu .react-tabs__tab-panel--selected,
.lobby .room-menu .react-tabs__tab-panel--selected  {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-left: 0;
  padding-right: 2px;
}

.lobby .room-menu {
  height: 100%;
}

.main-lobby ul.react-tabs__tab-list {
  display: flex;
  font-size: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  flex: 0 0 auto;
}

.lobby .current-users-menu {
  height: 100%;
}

.lobby .current-users-menu ul {
  display: flex;
  flex-direction: column;
}

.lobby .announcements {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.lobby h2 {
  text-align: center;
  color: white;
  font-size: 1.5vw;
  margin: 0.25em 0;
}

.lobby h2 {
  font-size: 1.25vw;
}

.main-lobby {
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.main-lobby-nav {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0px;
  right: 0;
  height: 60px;
  z-index: 2;
  background: var(--color-bg-primary);
}

.main-lobby-nav ul {
  display: flex;
  padding-left: 64px;
  border-top: var(--border-thin);
}

.main-lobby-nav li {
  color: white;
  flex: 1;
  list-style: none;
  display: grid;
  place-content: center;
  height: 60px;
  border-left: var(--border-thin);
}

.main-lobby-nav li.active {
  background-color: var(--color-bg-secondary);
}

.main-lobby-nav li img {
  display: block;
  margin: 0 auto;
}

@media (width <= 960px) {
  .lobby nav {
    flex-wrap: wrap;
  }

  .lobby h2 {
    font-size: 4vw;
  }

  .lobby > .my-container {
    margin-top: 1em;
    max-height: 80vh;
  }

  .lobby-container {
    padding: 0;
  }

  .main-lobby {
    display: block;
    overflow-y: auto;
  }

  #root .main-lobby section {
    width: auto;
    height: calc(100vh - 68px);
    margin: 4px;
  }

  .main-lobby section:not(.active) {
    display: none;
  }

  .lobby .user-menu {
    width: auto;
  }

  .main-lobby-nav {
    display: block;
  }

  .custom-bg {
    background-position-x: center;
  }
}
