.timer-bar {
  position: absolute;
  top: 100%;
  left: -4px;
  right: -4px;
  height: 12px;
  background: black;
  border-radius: 0 0 8px 8px;
}

.timer-bar-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  background: #f7d51d;
  border-radius: 0 0 12px 12px;
  border: var(--border-thick);
  transition: all linear 1s;
}
