.my-container {
  border-radius: 12px;
  background-color: var(--color-bg-primary);
  border: var(--border-thick);
  padding: 0.25em 0.5em;
  color: white;
}

.my-box {
  background-color: var(--color-bg-secondary);
  border-radius: 12px;
  border: var(--border-thin);
  padding: 0.25em 0.5em;
  color: white;
}

.my-box.clickable,
.clickable > .my-box {
  box-shadow: var(--shadow-clickable);
}
