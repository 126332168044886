.user-chat {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  overflow-y: auto;
}

.user-chat h2 {
  text-align: center;
}

.user-chat form {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: stretch;
  margin-top: 0.25em;
  gap: 0.25em;
}

.user-chat form input {
  flex: 1;
}

.user-chat form button {
  flex: 0;
}

.chat-history {
  flex: 1;
  overflow-y: auto;
}

.chat-history .date {
  color: white;
  width: 100%;
  text-align: center;
}

.chat button {
  align-self: start;
}

.chat-message-author span {
  line-height: 1.2em;
}

.chat-message-container {
  display: flex;
  flex-direction: column;
  margin: 0.25em 0;
  border-radius: 8px;
  font-size: 1.25rem;
  border: var(--border-thin);
  background: rgba(97, 115, 138, 0.815);
  color: white;
  cursor: var(--cursor-hover);
}

.chat-message-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chat-user {
  display: flex;
  gap: 8px;
  padding: 6px;
  background-color: var(--color-bg-secondary);
  border-bottom: var(--border-thin);
  border-radius: 4px 4px 0 0;
}

.chat-message {
  font-size: 1rem;
  line-height: 1.2em;
  word-break: break-word;
  padding: 6px;
}

.author-and-time {
  font-size: 1rem;
  width: 100%;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-message-author {
  color: var(--color-fg-gold);
}

.chat-message-time {
  font-size: 0.75rem;
}

.remove-chat {
  align-self: center;
}

.same-user {
  background-color: rgba(17, 148, 236, 0.604);
}

.server-message {
  background-color: var(--color-bg-accent);
}
