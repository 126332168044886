.game-history-list {
  display: flex;
  flex-direction: column;
}

.game-history-list h2 {
  margin: 0.5em 0;
  text-align: center;
}

.game-history-list div {
  flex: 1;
  overflow-y: auto;
}

.game-history.my-box {
  display: grid;
  align-items: center;
  gap: 0 0.5em;
  grid-template-areas:
    "top elo syn team"
    "date date syn team";
  grid-template-columns: 5ch 7ch 120px 1fr;
  background-color: var(--color-bg-secondary);
  margin: 0 0 0.5em 0;
  padding: 0.25em;
  justify-items: center;
  border: var(--border-thin);
}

.game-history .player-team-pokemons {
  padding-left: 0;
  padding-bottom: 10px;
  grid-area: team;
  align-self: start;
  justify-self: start;
}

.game-history .top {
  grid-area: top;
}
.game-history .elo {
  grid-area: elo;
}

.game-history .date {
  grid-area: date;
  color: gray;
  font-size: 0.8em;
  margin: 0;
}

.game-history .synergies {
  grid-area: syn;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  justify-self: start;
  list-style: none;
  padding: 0;
  margin: 0;
}

.game-history .synergies > li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-history .synergies > li > span {
  line-height: 80%;
}

@media (width <= 640px) {
  .game-history.my-box {
    grid-template-areas:
      "top elo date"
      "syn syn syn"
      "team team team";
    grid-template-columns: 5ch 7ch 1fr;
  }
}
