.react-tooltip {
  font-size: 1rem;
  max-width: 95vw;
}

.react-tooltip output {
  color: white;
}

.react-tooltip .help {
  font-size: 90%;
  font-style: italic;
  color: #e0e0e0;
}

.react-tooltip p:last-child {
  margin-bottom: 0;
}
