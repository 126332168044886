.pokemon-typeahead .form-control {
  color: #fff;
  background-color: #40404080;
  font-size: 1em;
  border-radius: 12px;
  width: 100%;
  border: var(--border-thin);
  padding: 0 0.75rem;
  min-height: 1em;
}

.pokemon-typeahead .form-control::placeholder {
  color: #ccc;
}
