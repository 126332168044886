.pokemon-emotion.my-box {
  display: flex;
  flex-flow: column;
  cursor: var(--cursor-hover);
  padding: 5px;
  background-color: var(--color-bg-secondary);
}

.pokemon-emotion > img {
  width: 80px;
  height: 80px;
  image-rendering: pixelated;
  border-radius: 8px 8px 0 0;
}

.pokemon-emotion:not(.unlocked, .unlockable) {
  filter: grayscale(1) contrast(0.5);
}

.pokemon-emotion.unlocked {
  filter: grayscale(0) contrast(1);
}
.pokemon-emotion.unlockable:not(.unlocked) {
  filter: grayscale(1) contrast(1);
}
.pokemon-emotion.unlockable:not(.unlocked):hover {
  filter: grayscale(0) contrast(0.8);
}

.pokemon-emotion.selected.my-box {
  background-color: var(--color-bg-accent);
}

.pokemon-emotion > p {
  line-height: 1em;
  max-width: 80px;
  word-break: break-word;
  font-weight: 500;
  font-size: 90%;
}

.pokemon-emotion .shortcut {
  font-size: 0.5em;
  position: absolute;
  top: 0;
  right: 0;
  background: #00000080;
  border-radius: 8px;
  padding: 0 4px;
}

.pokemon-emotion p > * {
  vertical-align: middle;
  color: white;
}

.pokemon-emotion p > img {
  width: 20px;
  height: 20px;
  image-rendering: pixelated;
  margin-left: 0.5em;
}
