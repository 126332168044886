.game-jukebox-modal select {
  display: block;
  width: 100%;
}

.game-jukebox-modal .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.game-jukebox-modal .compact-disc {
  position: relative;
}

.game-jukebox-modal .compact-disc > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
}

.game-jukebox-modal .compact-disc img {
  height: 80px;
  width: 80px;
  animation: rotate infinite 2s linear;
  filter: invert();
}

.game-jukebox-modal .compact-disc.loading img {
  animation: rotate infinite 1s reverse;
  opacity: 0.25;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
