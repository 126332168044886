
#play-panel p {
  font-size: 150%;
  color: white;
  margin-bottom: 2em;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

#play-panel .actions {
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  gap: 0.5em;
  align-items: start;
  list-style: none;
  padding: 0;
}

#play-panel .actions a {
  text-decoration: none;
}

#play-panel .actions .bubbly {
  min-width: 8em;
}

@media (max-width: 640px) {
  #play-panel .actions {
    flex-direction: row;
  }
}
