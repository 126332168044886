.room-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  margin-top: 0.5em;
}

.room-item > span {
  flex: 1;
  word-break: break-word;
}

.room-item .icon {
  width: 2em;
  height: 2em;
  &.noelo,
  &.scribble,
  &.quickplay {
    border-radius: 50%;
  }
}
